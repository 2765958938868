import React from 'react';

const QAPairs = [
  {
    q: <p>Where is the old hack day website? I need something from it.</p>,
    a: (
      <p>
        It is located at{' '}
        <a href="https://go.infoscoutinc.com/oldhackday">
          go/oldhackday
        </a>
        . Please let <a href="mailto:mark.delcambre@numerator.com">Mark Delcambre</a> know what
        information is missing so he can update the site.
      </p>
    ),
  },
  {
    q: <p>What if my question is not addressed?</p>,
    a: (
      <p>
        Please send an email to <a href="mailto:mark.delcambre@numerator.com">Mark Delcambre</a>.
      </p>
    ),
  },
  {
    q: <p>What are those "go/" things all over?</p>,
    a: (
      <p>
        Don't have 'Go Links'?{' '}
        <a href="https://go.infoscoutinc.com/extension">
          Install the chrome extension here
        </a>
        .
      </p>
    ),
  },
  {
    q: <p>Where can I find an idea or team to work with/on?</p>,
    a: (
      <p>
        Check out the Hack Ideas tab on this site for ideas and teams or join the{' '}
        <a href="http://numerator.slack.com/app_redirect?channel=hackday">
          #hackday slack channel
        </a>
        .
      </p>
    ),
  },
  {
    q: <p>Can we work with a team?</p>,
    a: <p>Absolutely, teams are heavily encouraged though we ask to keep it to 5 people max. </p>,
  },
  {
    q: <p>Is travel required?</p>,
    a: (
      <p>
        No! You can participate from your existing location. If you join a team, I recommend having
        a kickoff the morning of Day 1 and appropriate touchpoints with your team throughout the two
        days.{' '}
      </p>
    ),
  },
  {
    q: <p>Can remote employees participate or teams spread across multiple offices?</p>,
    a: (
      <p>
        Of course. We do encourage teams to have a good balance of people in different locations and
        develop a good plan to communicate throughout the hack. We want to avoid one team member
        from being left out while the rest of the group works in a single location.
      </p>
    ),
  },
  {
    q: <p>Are hacks limited to engineering?</p>,
    a: (
      <p>
        No! Content marketing posts, culture hacks, digital marketing hacks, and sales materials
        have all been successful hacks in the past.
      </p>
    ),
  },
  {
    q: <p>What are some successful hacks in the past?</p>,
    a: (
      <p>
        Some of the winning hacks in the past have been the 360live Chrome Extension, Auto-Scaling
        Crawlers, Receipt Hog Instant Surveys, Insights Smart People Groups, Serverless AWS with
        Lambda/Zappa, leveraging Snowflake Compute as a data lake, computer vision brand
        classification, and the ubiquitous go/links. Past hackday presentations can be found at
        go/HackDayPresentations.
      </p>
    ),
  },
  {
    q: <p>What if my hack doesn’t fit into one of the categories?</p>,
    a: (
      <p>
        First, feel free to submit it to the one that makes the most sense. Alternatively, you have
        that crazy hack that really just doesn’t fit? Submit under the Other Category. (note: you
        will only be eligible for the Popular Vote award).
      </p>
    ),
  },
  {
    q: <p>Is there a presentation template?</p>,
    a: (
      <p>
        Yep, you can find it here:{' '}
        <a href="https://go.infoscoutinc.com/Hackdaytemplate">
          go/HackDayTemplate
        </a>
        .
      </p>
    ),
  },
  {
    q: <p>I need GPU access for my machine learning hack, who do I talk to?</p>,
    a: (
      <p>
        Please reach out to{' '}<a href="mailto:mark.delcambre@numerator.com">Mark Delcambre</a>.
      </p>
    ),
  },
  {
    q: <p>Can I use Google Collab for my hack?</p>,
    a: (
      <p>
        No, please do not use any internal data with Google Collab or any similar free compute
        resource. Please reach out to{' '}
        <a href="mailto:mark.delcambre@numerator.com">Mark Delcambre</a>.
      </p>
    ),
  },
];

export default QAPairs;
