import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import SEO from '../components/common/seo';
import QAPairs from '../components/QAPairs.js';
import Card from '../components/Card';

const FAQPage = () => {
  return (
    <>
      <SEO title="FAQ" />
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }} />
      <div className="container">
        <h1>Frequently Asked Questions</h1>
        <div className="questions">
          {QAPairs.map(item => (
            <Card key={uuidv4()} body={item.a}>
              {' '}
              <h3 id="card-title-text">{item.q}</h3>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQPage;
